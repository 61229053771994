<template>
    <div class="c-layout-simple">
        <header class="w-full text-center">
            <slot name="header" />
        </header>
        <div class="c-page-frame relative">
            <div class="c-page-frame__content u-scrollbar w-full p-8">
                <div class="u-scrollbar" style="--scrollbar-height: 10px">
                    <slot />
                </div>
                <footer class="c-buttons">
                    <slot name="buttons" />
                </footer>
            </div>
            <div class="c-page-frame__background u-gradient-border h-full w-full"></div>
        </div>
        <footer class="w-full">
            <slot name="footer" />
        </footer>
    </div>
</template>

<style lang="scss" scoped>
.c-layout-simple {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}
</style>
